<template>
    <div class="col-12 col-sm-4 col-md-3 col-lg-2 single-album-item t c p" style="max-width: 540px;">
        <div class="single-album">
            <router-link :to="{name: 'podcast', params: { id: broadcast.id }}">
                <img src="/images/podcast-icon.svg" class="card-img" style="max-width: 190px;" alt="..." />
            </router-link>
            <div class="album-info">
                <small style="color: #d0d0d0;"><router-link :to="{name: 'podcast', params: { id: broadcast.id }}" class="card-title" style="color: white;">{{broadcast.name}}</router-link></small>
                <h6><router-link :to="{name: 'author', params: { id: broadcast.by.id }}">{{broadcast.by.name}}</router-link></h6>
                <p><small class="text-muted">&copy; {{getDate(broadcast.releaseInfo.releaseDate)}} Flabra Music</small></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BroadcastsItem',
    props: {
        broadcast: Object,
    },
    methods: {
        getDate(date) {
            var d = new Date(date);
            return (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
        },
        getDuration(secs) {
            return Math.ceil(secs / 60);
        }
    }
}
</script>