<template>
    <section class="hero-wrap clearfix" style="background-size: cover; background-repeat: no-repeat; background-image: url(/images/flabra2.jpg);" data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container container-top">
            <div class="text">
                <h1></h1>
                <div class="card mb-3 bg-dark" style="max-width: 540px;">
                    <div class="row no-gutters">
                        <!-- <div class="col-md-4">
                            <AuthImage :src="[album.images.filter((item) => item.purpose === 'thumbnail')[0].href]" class="card-img" style="max-width: 190px;" alt="..." />

                        </div> -->
                        <div class="col-12">
                            <div class="card-body">
                                <h5 class="card-title" style="color: white;">{{ author.name }}</h5>
                                <p v-if="authorType == 'OrdinaryPerson'" class="card-text">
                                    {{ author.name }} is a musician contributing to Flabra.
                                </p>
                                <p v-if="authorType == 'FictionalCharacter'" class="card-text">
                                    {{ author.name }} is a fictional character played by <router-link class="fb-meta" :to="{name: 'author', params: {id: involvedAuthors[0].id}}">{{ involvedAuthors[0].name }}</router-link>.
                                </p>
                                <p v-if="authorType == 'Pseudonym'" class="card-text">
                                    {{ author.name }} is a pseudonym used by <router-link class="fb-meta" :to="{name: 'author', params: {id: involvedAuthors[0].id}}">{{ involvedAuthors[0].name }}</router-link>.
                                </p>
                                <p v-if="authorType == 'Alias'" class="card-text">
                                    {{ author.name }} is an alias for <router-link class="fb-meta" :to="{name: 'author', params: {id: involvedAuthors[0].id}}">{{ involvedAuthors[0].name }}</router-link>.
                                </p>
                                <p v-if="authorType == 'Band'" class="card-text">
                                    {{ author.name }} is a group, which is mainly associated with the following members:
                                    <ul>
                                        <li :key="member.id" v-for="member in involvedAuthors">
                                            <router-link class="fb-meta" :to="{name: 'author', params: { id: member.id }}">{{ member.name }}</router-link>
                                        </li>
                                    </ul>
                                </p>
                                <p v-if="authorType != 'Band' && relatedBands.length > 0" class="card-text">
                                    {{ author.name }} has contributed to or is a member of the following band(s):
                                    <ul>
                                        <li :key="band.id" v-for="band in relatedBands">
                                            <router-link class="fb-meta" :to="{name: 'author', params: { id: band.id }}">{{ band.name }}</router-link>
                                        </li>
                                    </ul>
                                </p>
                                <p v-if="authorType != 'Band' && fictionals.length > 0" class="card-text">
                                    {{ author.name }} has played or still plays the following fictional character(s):
                                    <ul>
                                        <li :key="fictional.id" v-for="fictional in fictionals">
                                            <router-link class="fb-meta" :to="{name: 'author', params: { id: fictional.id }}">{{ fictional.name }}</router-link>
                                        </li>
                                    </ul>
                                </p>
                                <p v-if="authorType != 'Band' && pseudonyms.length > 0" class="card-text">
                                    {{ author.name }} has used or still uses the following pseudonyms:
                                    <ul>
                                        <li :key="pseudonym.id" v-for="pseudonym in pseudonyms">
                                            <router-link class="fb-meta" :to="{name: 'author', params: { id: pseudonym.id }}">{{ pseudonym.name }}</router-link>
                                        </li>
                                    </ul>
                                </p>
                                <p v-if="aliases.length > 0" class="card-text">
                                    {{ author.name }} is also known as:
                                    <ul>
                                        <li :key="alias.id" v-for="alias in aliases">
                                            <router-link class="fb-meta" :to="{name: 'author', params: { id: alias.id }}">{{ alias.name }}</router-link>
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
import { onUpdated } from '@vue/runtime-core';
import {fbGetAuthor} from '../assets/apiclient'

export default {
    name: 'AuthorDetail',
    data() {
        return {
            currentAuthorId: '',
            author: {},
            authorType: "",
            involvedAuthors: [],
            relatedBands: [],
            fictionals: [],
            pseudonyms: [],
            aliases: [],
        };
    },
    methods: {
        async loadAuthor(id) {
            this.author = await fbGetAuthor(id);
            this.currentAuthorId = this.author.id;
            this.authorType = this.author.metadata.PersonType;
            this.involvedAuthors = [];
            this.relatedBands = [];
            this.fictionals = [];
            this.pseudonyms = [];
            this.aliases = [];

            switch (this.author.metadata.PersonType) {
                case 'FictionalCharacter':
                    var origin = await fbGetAuthor(this.author.metadata.FictionalOf);
                    this.involvedAuthors.push(origin);
                    break;

                case 'Pseudonym':
                    var origin = await fbGetAuthor(this.author.metadata.PseudonymOf);
                    this.involvedAuthors.push(origin);
                    break;

                case 'Alias':
                    var origin = await fbGetAuthor(this.author.metadata.AliasFor);
                    this.involvedAuthors.push(origin);
                    break;

                case 'Band':
                    var memberIds = this.author.metadata.Members.split(',');
                    for (var i = 0; i < memberIds.length; i++) {
                        var member = await fbGetAuthor(memberIds[i]);
                        this.involvedAuthors.push(member);
                    }
                    break;

                default:
                    break;
            }

            if (this.author.metadata.RelatedBands != null) {
                var bandIds = this.author.metadata.RelatedBands.split(',');
                for (var i = 0; i < bandIds.length; i++) {
                    var band = await fbGetAuthor(bandIds[i]);
                    this.relatedBands.push(band);
                }
            }

            if (this.author.metadata.Fictionals != null) {
                var ids = this.author.metadata.Fictionals.split(',');
                for (var i = 0; i < ids.length; i++) {
                    var item = await fbGetAuthor(ids[i]);
                    this.fictionals.push(item);
                }
            }

            if (this.author.metadata.Pseudonyms != null) {
                var ids = this.author.metadata.Pseudonyms.split(',');
                for (var i = 0; i < ids.length; i++) {
                    var item = await fbGetAuthor(ids[i]);
                    this.pseudonyms.push(item);
                }
            }

            if (this.author.metadata.Aliases != null) {
                var ids = this.author.metadata.Aliases.split(',');
                for (var i = 0; i < ids.length; i++) {
                    var item = await fbGetAuthor(ids[i]);
                    this.aliases.push(item);
                }
            }
        }
    },
    async created() {
        this.involvedAuthors = [];
        return await this.loadAuthor(this.$route.params.id);
    },
    async updated() {
        if (this.currentAuthorId != this.$route.params.id)
            return await this.loadAuthor(this.$route.params.id);
    }
}
</script>