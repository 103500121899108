<template>
<section class="hero-wrap clearfix" style="background-size: cover; background-repeat: no-repeat; background-image: url(images/flabra3.jpg);" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container container-top">
        <div class="card mb-3 bg-dark col-md-8">
            <h3 class="card-header">
                Profile
            </h3>
            <div class="card-body">
                <form>
                    <div :key="property.id" v-for="property in properties">
                        <ProfileProperty :property="property" @profile-property-update="updateProperty" />
                    </div>                    
                </form>
                <div :key="componentKey">
                    <h4>2FA</h4>
                    <div :key="property.id" v-for="property in mfaProperties">
                        <ProfileProperty :property="property" @profile-property-update="updateProperty" />
                    </div>                    
                    <div v-if="isMfaEnabled()">
                        <h4>2FA devices</h4>
                        <div :key="property.id" v-for="property in deviceProperties">
                            <ProfileDeviceProperty :property="property" @profile-property-device-update="updateDeviceProperty" @profile-propery-device-delete="deleteDevice"/>
                        </div>
                        <router-link :to="{name: 'profile2faEnable'}" class="btn btn-primary">Add 2FA device</router-link>
                    </div>
                    <div v-if="!isMfaEnabled()">
                        <router-link :to="{name: 'profile2faEnable'}" class="btn btn-primary">Enable 2FA</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="modalDisable" tabindex="-1" role="dialog" aria-labelledby="modalDisableLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirm your choice</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="modalDisable-modal-body" class="modal-body">
        <strong>Do you really want to disable 2FA for this account?</strong><br/>
        This will also delete your stored authenticator devices and recovery keys. You can re-enable 2FA at anytime.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="disableMfa()">Proceed</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import ProfileProperty from '../components/ProfileProperty'
import ProfileDeviceProperty from '../components/ProfileDeviceProperty.vue'
import {fbGetUserClaims, fbSetUserClaim, fbGetUsername, fbGetMfaInfo, fbDeleteMfaDevice, fbRenameMfaDevice} from '../assets/apiclient.js'

export default {
    components: {
        ProfileProperty,
        ProfileDeviceProperty,
    },
    name: 'Profile',
    data() {
        return {
            properties: [],
            mfaProperties: [],
            deviceProperties: [],
            mfaInfo: null,
            componentKey: 0
        }
    },
    methods: {
        isMfaEnabled() {
            if (this.mfaInfo == null)
                return false;

            return true;
        },
        disableMfa() {
            if (this.mfaInfo != null) {
                this.mfaInfo.devices.forEach(element => {
                    fbDeleteMfaDevice(element);
                });
                location.reload();
            }
        },
        async updateProperty(id, value) {
            var newval = this.properties.find(_ => _.id == id);
            var propertyChanged = newval.value != value;
            newval.value = value;
            var newprops = [];
            this.properties.forEach(_ => {
                if (_.id != id)
                    newprops.push(_);
                else
                    newprops.push(newval);
            });
            this.properties = newprops;

            if (propertyChanged)
                await fbSetUserClaim(id, value);
        },
        async updateDeviceProperty(id, value) {
            var newval = this.deviceProperties.find(_ => _.id == id);
            if (newval.value == value)
                return;
            
            var oldvalue = newval.value;
            newval.value = value;
            var newprops = [];
            this.deviceProperties.forEach(_ => {
                if (_.id != id)
                    newprops.push(_);
                else
                    newprops.push(newval);
            });
            this.deviceProperties = newprops;

            await fbRenameMfaDevice(oldvalue, value);
        },
        async deleteDevice(id) {
            var newval = this.deviceProperties.find(_ => _.id == id);
            var newprops = [];
            this.deviceProperties.forEach(_ => {
                if (_.id != id)
                    newprops.push(_);
            });
            this.deviceProperties = newprops;

            await fbDeleteMfaDevice(newval.value);
            location.reload();
        }, 
        accessLevelToString(n) {
            if (n >= 255)
                return 'Admin';

            if (n >= 127)
                return 'Editor';

            if (n < 0 || n > 5)
                return 'Unknown';

            var accessLevelMap = ['Unknown', 'Anonymous', 'Member', 'Premium Member', 'Mate', 'Intimate'];
            return accessLevelMap[n];
        }
    },
    async created() {
        var data = await fbGetUserClaims();
        console.log(data);
        this.properties = [
            {
                id: "username",
                name: "Username",
                value: fbGetUsername(),
                editable: false,
                description: 'This is your login name. It cannot be changed.',
            },
            {
                id: "accesslevel",
                name: "AccessLevel",
                value: data.AccessLevel !== undefined ? this.accessLevelToString(data.AccessLevel) : 'unknown',
                editable: false,
                description: 'Based on your AccessLevel, you will be able to download and listen to certain items, while others might not even be visible for you. The following AccessLevels are built on top of each other, which means that every level listed here always includes the permissions of its predecessors: <ul class="list-group"><li class="list-group-item"><strong>Anonymous</strong> - default when not logged in with a real account</li><li class="list-group-item"><strong>Member</strong> - default when logged in with a real account</li><li class="list-group-item"><strong>Premium Member</strong> - for members who e.g. donated or provide support in another way, additional content is available</li><li class="list-group-item"><strong>Mate</strong> - commonly referred to as friends and family, this AccessLevel additionally contains material that can be very <strong>intense</strong> in various ways</li><li class="list-group-item"><strong>Intimate</strong> - you will even have access to material we wished to never face the public</li></ul>',
            },
            {
                id: "name",
                name: "Name",
                value: data.name !== undefined ? data.name : '',
                editable: true,
                description: 'Your full name, firstname or nickname. This is part of your profile (optional).',
            },
            {
                id: "mailaddress",
                name: "E-mail",
                value: data.mailaddress !== undefined ? data.mailaddress : '',
                editable: true,
                description: 'Your e-mail address is optional. If you specify it, you will be able to reset your password in case you forgot it, which is not possible otherwise.',
            },
        ];
        this.mfaProperties = [
            {
                id: "2fa-status",
                name: "2FA Status",
                value: "disabled",
                editable: false,
                description: '',
            },            
        ];
        console.log("Obtain MfaInfo")
        this.mfaInfo = await fbGetMfaInfo();
        if (this.mfaInfo != null) {
            this.componentKey++;
            this.mfaProperties = [
                {
                    id: "2fa-status",
                    name: "2FA Status",
                    value: "enabled since " + this.mfaInfo.createDate,
                    editable: false,
                    description: '',
                },  
                {
                    id: "2fa-account-status",
                    name: "2FA Health",
                    value: this.mfaInfo.status,
                    editable: false,
                    description: '',
                },  
                {
                    id: "2fa-retries",
                    name: "Total retries left",
                    value: this.mfaInfo.triesLeft,
                    editable: false,
                    description: '',
                },  
            ];
            this.deviceProperties = [];
            var count = 1;
            this.mfaInfo.devices.forEach(_ => {
                this.deviceProperties.push({
                    id: count,
                    value: _,
                });
                count++;
            });
        }
        console.log("Return MfaInfo")
        console.log("INFO-2FA", this.mfaInfo)
    },
    async updated() {
        $('[data-toggle="tooltip"]').tooltip();
    }
}
</script>

<style>
</style>
