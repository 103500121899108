<template>
    <div class="col-12 col-sm-4 col-md-3 col-lg-2 single-album-item t c p" style="max-width: 540px;">
        <div class="single-album">
            
            <router-link :to="{name: 'release', params: { id: album.id }}">
                <AuthImage :src="album.images.filter((item) => item.purpose === 'thumbnail')[0].href" class="card-img" style="max-width: 190px;" alt="..." />
            </router-link>

            <div class="album-info">
                <small style="color: #d0d0d0;"><router-link :to="{name: 'release', params: { id: album.id }}" >{{album.name}}</router-link></small>
                <h6><router-link :to="{name: 'author', params: { id: album.by.id }}" >{{album.by.name}}</router-link></h6>
                <p><small class="text-muted">{{album.releaseId}} (MP3-{{getDuration(album.duration)}})<br/>&copy; {{getDate(album.releaseInfo.releaseDate)}} Flabra Music</small></p>
            </div>
        </div>
    </div>
</template>

<script>
import AuthImage from './AuthImage.vue'

export default {
  components: { AuthImage },
    name: 'AlbumReleasesItem',
    props: {
        album: Object,
    },
    methods: {
        getDate(date) {
            var d = new Date(date);
            return (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
        },
        getDuration(secs) {
            return Math.ceil(secs / 60);
        }
    }
}
</script>