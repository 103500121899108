<template>
    <div v-if="audioData !== null" id="AudioPlayer">
        <audio :src="audioData" id="fbplayer" autoplay></audio>
    </div>
</template>

<script>
import axios from 'axios'
import {fbGetSong, fbGetBroadcast, fbLogin, fbGetAccessLevel, fbDirectDownloadEnabled} from '../assets/apiclient'
import {runAudioJs, clearAudioJs, setPlayTriggered, getPlayTriggered} from '../assets/audiojs-data'

export default {
    name: 'AudioPlayer',
    data() {
        return {
            audioData: null,
        }
    },
    props: {
        contribId: String,
        contribType: String,
    },
    methods: {
        async update() {
            if (getPlayTriggered()) {
                // console.log("play-song event for contrib id: " + this.contribId + " already triggered");
                return;
            }
            setPlayTriggered(true);
            console.log("Emit: song-playing", this.contribId);
            this.$emit('song-playing', this.contribId);
            clearAudioJs();
            var song;
            if (this.contribType != 'broadcast') {
                // console.log("AudioPlayer will load this as a song, because contribType is " + this.contribType, this.contribId);
                song = await fbGetSong(this.contribId);
            } else {
                // console.log("AudioPlayer will load this as a broadcast, because contribType is " + this.contribType, this.contribId);
                song = await fbGetBroadcast(this.contribId);
            }
            // console.log("AudioPlayer got a response", song);

            var chosenAudioIndex = 0;
            if (fbGetAccessLevel() != 0) {
                var bestLevel = 0;
                var index = 0;
                song.formats.forEach(element => {
                    // console.log("Looking at audio format", element);
                    // console.log("AccessLevel(audio format/mine): " + element.accessLevel + "/" + fbGetAccessLevel());
                    // console.log("Test: element.accessLevel <= fbGetAccessLevel", element.accessLevel <= fbGetAccessLevel());
                    // console.log("Test: element.accessLevel > bestLevel", element.accessLevel > bestLevel);
                    if (element.accessLevel <= fbGetAccessLevel() && element.accessLevel > bestLevel) {
                        bestLevel = element.accessLevel;
                        chosenAudioIndex = index;
                        // console.log("Choosing index " + chosenAudioIndex + ", new bestLevel is " + bestLevel);
                    }
                    index++;
                    // console.log("Index is at " + index);
                });
            }

            if (fbGetAccessLevel() != 0 && fbGetAccessLevel() < song.formats[chosenAudioIndex].accessLevel) {
                toastr.error("Sorry, your current access-level does not satisfy the access-level required by the song's audio data. Unless you login with a real account, I won't be able to play this piece of shit for you.");
                setPlayTriggered(false);
                return;
            }

            var dataUrl = song.formats[chosenAudioIndex].href;
            console.log("DataUrl", dataUrl);

            var response;
            if (fbDirectDownloadEnabled) {
                try {
                    response = await axios({
                        method: 'get',
                        headers: {
                            Range: "bytes=0-3"
                        },
                        url: dataUrl + "/play"
                    });
                } catch (e) {
                    console.log("Error loading contrib", dataUrl);
                    return fbLogin();
                }

                this.audioData = dataUrl + "/play";
            } else {
                try {
                    response = await axios({
                        method: 'get',
                        responseType: 'arraybuffer',
                        url: dataUrl + "/play"
                    });
                } catch (e) {
                    console.log("Error loading contrib", dataUrl);
                    return fbLogin();
                }

                var mimeType = response.headers['content-type'].toLowerCase();
                var data = Buffer.from(response.data, 'binary').toString('base64');
                this.audioData = "data:" + mimeType + ";base64," + data;
            }

            await runAudioJs(song, this,
                function(cbh, id) {
                    console.log("Song is playing", id);
                    cbh.$emit('song-playing', id);
                },
                function(cbh, id) {
                    console.log("Song has stopped", id);
                    cbh.$emit('song-ended', id);
                },
                function(cbh, id) {
                    console.log("Song has been paused", id);
                    cbh.$emit('song-paused', id);
                },
                function(cbh, secs) {
                    cbh.$emit('song-playtime', secs)
                });
            setPlayTriggered(false);
        }
    },
    async updated() {
        await this.update()
    },
    async created() {
        await this.update()
    }
}
</script>