<template>
<section class="hero-wrap clearfix" style="background-size: cover; background-repeat: no-repeat; background-image: url(images/flabra3.jpg);" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container container-top">
        <div class="card mb-3 bg-dark col-md-8">
            <h3 class="card-header">
                Profile - enable 2FA
            </h3>
            <div class="card-body">
                <div v-if="currentView == 0" :key="componentKey">
                    <p style="text-align: center;"><img :src="mfaChallenge.qrCodeDataUri"/></p>
                    <form @submit.prevent="edit(property.id)">
                        <p>Scan the code with your auth app. If you can't, put this in your auth app as the secret instead:</p>
                        <p style="text-align: center;"><strong>{{ mfaChallenge.secret }}</strong></p>
                        <div :key="property.id" v-for="property in properties" class="form-group row">
                            <label data-toggle="tooltip" data-placement="right" data-html="true" v-bind:for="property.id" class="col-sm-4 col-form-label">{{ property.name }}</label>
                            <div class="col-sm-8">
                                <div class="input-group mb-2 has-validation">     
                                    <input type="text" class="form-control focusedInput bg-dark" v-bind:id="property.id" v-bind:value="property.value">
                                    <div class="input-group-prepend" v-if="property.id == 0">
                                        <a href="#" @click="edit(property.id)" class="input-group-text"><img v-bind:src="editOptionsImageUrl(property.id)" height="24" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div v-if="verificationSuccess == false">
                        <strong>Your verification code was not accepted, please try again.</strong>
                    </div>
                </div>
                <div v-if="currentView == 1">
                    Success! In case something goes wrong, please save the following recovery keys to a secure file location:
                    <pre>{{ recoveryKeys }}</pre>
                    <router-link :to="{name: 'profile'}" class="btn btn-primary">I copied the recovery keys</router-link>
                </div>
                <div v-if="currentView == 2">
                    Success! Your new device was additionally linked to your account.<br/><br/>
                    <router-link :to="{name: 'profile'}" class="btn btn-primary">Back to My Profile</router-link>
                </div>
            </div>
        </div>
    </div>
</section>

</template>

<script>
import {fbGetUserClaims, fbGetMfaInfo, fbGetMfaChallenge, fbGetMfaChallengeResponse} from '../assets/apiclient.js'

export default {
    components: {
    },
    name: 'Profile2faEnable',
    data() {
        return {
            properties: [{id: 0, name: "Verification Code", value: ""}, {id: 1, name: "Device Name", value: "default"}],
            data: [],
            currentView: null,
            mfaChallenge: null,
            mfaChallengeResponse: null,
            recoveryKeys: null,
            mfaInfo: null,
            componentKey: 0,
            currentEditingId: 0,
            verificationSuccess: null,
        }
    },
    methods: {
        isEditing(id) {
            return (id == this.currentEditingId)
        },
        async edit(id) {
            if (!this.isEditing(id)) {
                this.currentEditingId = id;
                return;
            }

            // commit...
            var verificationCode = document.getElementById("0").value;
            var deviceName = document.getElementById("1").value;
            console.log("VerficationCode", verificationCode);
            console.log("DeviceName", deviceName);
            try {
                this.mfaChallengeResponse = await fbGetMfaChallengeResponse(this.mfaChallenge.sessionToken, verificationCode, deviceName);
                if (this.mfaChallengeResponse.recoveryKeys.length > 0) {
                    this.recoveryKeys = "";
                    this.mfaChallengeResponse.recoveryKeys.forEach(element => {
                        this.recoveryKeys += "\n" + element;
                    });
                }
            } catch (ex) {
                this.verificationSuccess = false;
                this.property = {id: 0, name: "Verification Code", value: ""};
                return;
            }
            // this.$emit('profile-property-update', this.property.id, document.getElementById(this.property.id).value);
            this.currentEditingId = null;
            this.currentView = this.recoveryKeys != null ? 1 : 2;
        },
        editOptionsImageUrl(id) {
            return '/images/' + (this.isEditing(id) ? 'editok.png' : 'edit.png');
        }
    },
    async created() {
        this.data = await fbGetUserClaims();
        this.mfaInfo = await fbGetMfaInfo();
        console.log("DATA", this.data);
        console.log("MFAINFO", this.mfaInfo);
        if (this.data.AccessLevel > 1) { // && this.mfaInfo == null) {
            this.mfaChallenge = await fbGetMfaChallenge();
            this.componentKey++;
            this.currentView = 0;
        }
        console.log("CHALL", this.mfaChallenge);
    },
    async updated() {
        $('[data-toggle="tooltip"]').tooltip();
        $('#0').focus();
    }
}
</script>

<style>
</style>
