<template>
    <div class="container">
        <h5>MetaData</h5>
        <small>
        <p v-if="metadata.composition != null || metadata.lyrics != null">
            {{ stringifyWritingCreds() }}
        </p>
        <p v-if="metadata.recording != null && metadata.recording.performances.length > 0">
            Performed by:
            <ul style="margin-bottom: 0rem;" :key="performance.id" v-for="performance in metadata.recording.performances">
                <li>
                    <strong>{{ performance.by.name }}</strong>:
                    {{ stringifyContributionCategories(performance.contribution) }}
                </li>
            </ul>
        </p>

        <p v-if="metadata.mixing != null || metadata.mastering != null">
            {{ stringifyRecording() }}
            {{ stringifyProduction() }}
        </p>
        <p v-if="metadata.recording != null">
            {{ stringifyRecordingEquipment() }}
        </p>
        <p v-if="metadata.recording != null">
            {{ stringifyRecordingInstruments() }}
        </p>
        </small>
    </div>
</template>

<script>
export default {
    name: 'SongMetaData',
    props: {
        metadata: Object,
    },
    data() { 
        return {
            monthToggler: 0,
    }},
    methods: {
        joinEquipments(items) {
            var equips = [];
            items.forEach(element => {
                if (element.manufacturer.name != "Generic" || element.components?.length > 0) {
                    var name;
                    if (element.manufacturer.name == "Generic")
                        name = element.name;
                    else
                        name = element.manufacturer.name == element.name ? element.name : element.manufacturer.name + " " + element.name;
                    if (element.components != null && element.components.length > 0) {
                        var components = [];
                        element.components.forEach(component => {
                            components.push((component.manufacturer.name == "Generic" ? "" : component.manufacturer.name + " ")
                                + component.name);
                        });
                        name += " (" + components.join(", ") + ")";
                    }
                    if (!equips.includes(name))
                        equips.push(name);
                }
            });

            return equips.join(", ");
        },
        joinApiRefs(apiRefs) {
            var names = [];
            apiRefs.forEach(element => {
                names.push(element.name);
            });

            return names.join(", ");
        },
        joinApiRefsWithAnd(apiRefs) {
            var names = [];
            apiRefs.forEach(element => {
                names.push(element.name);
            });

            return names.join(" and ");
        },
        matchApiRefs(item1, item2) {
            if (item1.length != item2.length)
                return false;
            
            var list1 = this.joinApiRefs(item1);
            var list2 = this.joinApiRefs(item2);

            return list1 == list2;
        },
        stringifyBasicEvent(basicEvent) {
            return basicEvent.date + " by " + this.joinApiRefs(basicEvent.by);
        },
        stringifyContributionCategories(contribution) {
            var cats = [];
            contribution.forEach(element => {
                if (!cats.includes(element.category.name))
                    cats.push(element.category.name);
            });

            return cats.join(", ");
        },
        stringifyMonth(dt) {
            const dmonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            const elems = dt.split('/');
            // this.monthToggler++;

            switch (this.monthToggler % 4) {
                case 0:
                    return dt;
            
                case 1:
                    return "in " + dmonth[elems[0] - 1] + " " + elems[1];

                case 2:
                    return "in " + dt;

                case 3:
                    return "in the month of " + dmonth[elems[0] - 1] + " " + elems[1];

                default:
                    break;
            }
        },
        stringifyDate(dt) {
            // actual date?
            if (dt.split("-").length == 3) {
                var date = new Date(dt);
                return "on " + date.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
            }

            // a span?
            var items = dt.split("-");
            if (items.length == 2)
                return "between " + items[0] + " and " + items[1];
            
            // a month?
            if (dt.split("/").length == 2) {
                return this.stringifyMonth(dt);
            }

            // a year?
            if (dt.length == 4)
                return "in " + dt;

            return dt;
        },
        stringifyWritingCreds() {
            if (this.metadata.composition == null && this.metadata.lyrics == null)
                return "";
            
            var res = "";
            if (this.metadata.composition != null)
                res += "Composed " + this.stringifyDate(this.metadata.composition.date) 
                    + " by " + this.joinApiRefs(this.metadata.composition.by) + ". ";

            if (this.metadata.lyrics != null)
                res += "Texted " + this.stringifyDate(this.metadata.lyrics.date) 
                    + " by " + this.joinApiRefs(this.metadata.lyrics.by) + ". ";

            if (this.metadata.composition != null && this.metadata.lyrics != null) {
                if (this.matchApiRefs(this.metadata.composition.by, this.metadata.lyrics.by)) {
                    if (this.metadata.composition.date == this.metadata.lyrics.date)
                        res = "Composed and texted " + this.stringifyDate(this.metadata.composition.date)
                            + " by " + this.joinApiRefs(this.metadata.composition.by) + ". ";
                    else
                        res = "Composed " + this.stringifyDate(this.metadata.composition.date)
                            + " by " + this.joinApiRefs(this.metadata.composition.by) + ". The composer(s) also"
                            + " texted the lyrics for the song " + this.stringifyDate(this.metadata.lyrics.date) + ". ";
                } else {
                    if (this.metadata.composition.date == this.metadata.lyrics.date)
                        res = 'Composed by ' + this.joinApiRefs(this.metadata.composition.by)
                            + ' and texted by ' + this.joinApiRefs(this.metadata.lyrics.by) + ', both ' 
                            + this.stringifyDate(this.metadata.lyrics.date) + '. ';
                }
            }

            return res;
        },
        stringifyRecording() {
            if (this.metadata.recording == null)
                return "";

            return "Recorded " + this.stringifyDate(this.metadata.recording.date) + " by "
                + this.joinApiRefsWithAnd(this.metadata.recording.by) + " at "
                + this.joinApiRefsWithAnd(this.metadata.recording.locations) + ". ";
        },
        countNonGenericEquipment(equipments) {
            var res = 0;
            equipments.forEach(element => {
                if (element.manufacturer.name != "Generic" || element.components.length > 1)
                    res++;
            });

            return res;
        },
        stringifyRecordingEquipment() {
            if (this.metadata.recording == null)
                return "";

            var res = "";
            if (this.metadata.recording.equipment != null && this.countNonGenericEquipment(this.metadata.recording.equipment) > 0)
                res += "Recording equipment: " + this.joinEquipments(this.metadata.recording.equipment) + ". ";

            return res;
        },
        stringifyRecordingInstruments() {
            if (this.metadata.recording == null || this.metadata.recording.performances == null || this.metadata.recording.performances.length == 0)
                return "";
            
            var allInstruments = [];
            this.metadata.recording.performances.forEach(performance => {
                performance.contribution.forEach(element => {
                    allInstruments.push(element);
                });
            });
            var res = "";
            if (this.countNonGenericEquipment(allInstruments) > 0)
                res += "Sound equipment and instruments: " + this.joinEquipments(allInstruments) + ". ";

            return res;
        },
        stringifyProduction() {
            if (this.metadata.mixing == null && this.metadata.mastering == null)
                return "";
            
            var res = "";
            if (this.metadata.mixing != null)
                res += "Mixed " + this.stringifyDate(this.metadata.mixing.date) + " by "
                    + this.joinApiRefs(this.metadata.mixing.by) + " at "
                    + this.joinApiRefs(this.metadata.mixing.locations) + ". ";

            if (this.metadata.mastering != null)
                res += "Mastered " + this.stringifyDate(this.metadata.mastering.date) + " by "
                    + this.joinApiRefs(this.metadata.mastering.by) + " at "
                    + this.joinApiRefs(this.metadata.mastering.locations) + ". ";

            if (this.metadata.mixing != null && this.metadata.mastering != null)
                if (this.matchApiRefs(this.metadata.mixing.locations, this.metadata.mastering.locations))
                    if (this.matchApiRefs(this.metadata.mixing.by, this.metadata.mastering.by))
                        if (this.metadata.mixing.date == this.metadata.mastering.date)
                            res = "Mixed and mastered by " + this.joinApiRefs(this.metadata.mixing.by)
                                + " at " + this.joinApiRefs(this.metadata.mixing.locations)
                                + " " + this.stringifyDate(this.metadata.mixing.date) + ". ";
                        else
                            res = "Mixed and mastered by " + this.joinApiRefs(this.metadata.mixing.by)
                                + " at " + this.joinApiRefs(this.metadata.mixing.locations)
                                + ". Mixing took place " + this.stringifyDate(this.metadata.mixing.date)
                                + ", finally mastered " + this.stringifyDate(this.metadata.mastering.date) + ". ";
                    else
                        res = "Mixed and mastered at " + this.joinApiRefs(this.metadata.mixing.locations)
                            + ". Mixing was done " + this.stringifyDate(this.metadata.mixing.date)
                            + " by " + this.joinApiRefs(this.metadata.mixing.by)
                            + ". Mastered by " + this.joinApiRefs(this.metadata.mastering.by)
                            + " " + this.stringifyDate(this.metadata.mixing.date) + ". ";
            
            return res;
        }
    }
}
</script>
