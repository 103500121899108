import axios from 'axios'

var config = {
    authority: "${OIDC_AUTHORITY}",
    client_id: "jsclient",
    redirect_uri: "${APPLICATION_BASEURL}/callback.html",
    response_type: "code",
    scope:"openid profile Flabra.Api.Planet.Service",
    post_logout_redirect_uri : "${APPLICATION_BASEURL}/index.html",
};

const fbDirectDownloadEnabled = true;
var apiUrl="${API_BASEURL}"
var mgr = new Oidc.UserManager(config);
var axiosSet = false;
var hasGetUser = false;
var userAccessLevel = 0;
var userSub = null;
var userName = null;

async function fbPreflight() {
    console.log("fbPreflight(): entering, hasGetUser:", hasGetUser)
    if (!hasGetUser) {
        let user = await mgr.getUser();
        if (user) {
            console.log("User is logged in", user);
            userAccessLevel = user.profile.AccessLevel;
            userSub = user.profile.sub;
            if (!axiosSet) {
                console.log("Configuring axios");
                axiosSet = true;
                axios.defaults.headers.common['Authorization'] = "Bearer " + user.access_token
            }

            var url = apiUrl + "/v1/users/id/" + userSub;

            var res = await axios.get(url);
            let results = res.data;
            console.log(results);
            userName = results.username;
        }
        else {
            console.log("User not logged in");
        }
        hasGetUser = true
    }
}

fbPreflight();

function fbLogin() {
    var currentUrl = window.location.href;
    console.log("fbLogin() saving current url", currentUrl);
    if (sessionStorage.getItem('fbapi-url') != null) {
        sessionStorage.removeItem('fbapi-url');
    }
    sessionStorage.setItem('fbapi-url', currentUrl);
    mgr.signinRedirect();
}

const timer = ms => new Promise(res => setTimeout(res, ms))

async function fbIsLoggedIn() {
    while (!hasGetUser) {
        console.log("fbIsLoggedIn(): waiting for fbPreflight to initialize")
        await timer(500)
    }

    if (axiosSet) {
        console.log("fbIsLoggedIn(): user is logged in")
    } else {
        console.log("fbIsLoggedIn(): user is not logged in")
    }
    return axiosSet;
}

function fbGetAccessLevel() {
    return userAccessLevel;
}

async function fbGetAlbums() {
    var url = apiUrl + "/v1/albums";

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

async function fbGetAlbum(id) {
    var url = apiUrl + "/v1/albums/" + id;

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

async function fbGetVideo(id) {
    var url = apiUrl + "/v1/videos/" + id;

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

async function fbGetSong(id) {
    var url = apiUrl + "/v1/songs/" + id;

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

async function fbGetMetaData(id) {
    var url = apiUrl + "/v1/metadata/contribs/" + id + "/summary";

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

async function fbGetBroadcasts() {
    var url = apiUrl + "/v1/broadcasts";

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

async function fbGetBroadcast(id) {
    var url = apiUrl + "/v1/broadcasts/" + id;

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

async function fbSubscribeChannel(id) {
    var url = apiUrl + "/v1/rss-feeds/podcasts/" + id + "/subscribe";

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

async function fbGetAuthor(id) {
    var url = apiUrl + "/v1/persons/" + id;

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

function fbGetUserId() {
    return userSub;
}

function fbGetUsername() {
    return userName;
}

async function fbGetUserClaims() {
    var url = apiUrl + "/v1/users/id/" + userSub + "/claims";

    var res = await axios.get(url);
    let results = res.data;
    console.log(results);
    return results;
}

async function fbSetUserClaim(key, value) {
    var url = apiUrl + "/v1/users/id/" + userSub + "/claims";

    var res = await axios.put(url, {
        key: key,
        value: value
    });
}

async function fbGetMfaInfo() {
    console.log("fbGetMfaInfo entering")
    while (userSub == null) {
        console.log("SLEEP ON MFAINFO")
        await timer(500)
    }

    var url = apiUrl + "/v1/users/id/" + userSub + "/2fa/info";

    console.log("fbGetMfaInfo calling")
    try {
        var res = await axios.get(url);
        console.log("fbGetMfaInfo Got data", url, res);
        return res.data;
    } catch(error) {
        console.log("fbGetMfaInfo call failed", error.toJSON());
        return null;
    }
}

async function fbGetMfaChallenge() {
    var url = apiUrl + "/v1/users/id/" + userSub + "/2fa/challenge";

    var res = await axios.get(url);
    return res.data;
}

async function fbGetMfaChallengeResponse(sessionToken, verificationCode, deviceName) {
    var url = apiUrl + "/v1/users/id/" + userSub + "/2fa/challenge/" + sessionToken + "/" + verificationCode + "/" + deviceName;

    var res = await axios.post(url);
    return res.data;
}

async function fbDeleteMfaDevice(deviceName) {
    var url = apiUrl + "/v1/users/id/" + userSub + "/2fa/devices/" + deviceName;

    var res = await axios.delete(url);
    return res.data;
}

async function fbRenameMfaDevice(deviceName, newDeviceName)
{
    var url = apiUrl + "/v1/users/id/" + userSub + "/2fa/devices/" + deviceName + "/rename/" + newDeviceName;

    var res = await axios.put(url);
    return res.data;
}

function fbLogout() {
    mgr.signoutRedirect();
}

export {fbLogin, fbIsLoggedIn, fbGetAccessLevel, fbGetUserId, fbGetUsername, fbGetUserClaims, fbSetUserClaim, fbGetMfaInfo, fbGetMfaChallenge, fbGetMfaChallengeResponse, fbDeleteMfaDevice, fbRenameMfaDevice,fbLogout, fbGetAlbums, fbGetAlbum, fbGetVideo, fbGetSong, fbGetMetaData, fbGetBroadcasts, fbGetBroadcast, fbSubscribeChannel, fbGetAuthor, fbDirectDownloadEnabled}