<template>
<section class="hero-wrap clearfix" style="background-size: cover; background-repeat: no-repeat; background-image: url(/images/flabra3.jpg);" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container container-top">
        <div v-if="subscriptionLink != ''">
            <div class="card mb-3 bg-dark">
                <h1><img src="/images/rss.png" height="48"/> Subscribe to Radio FlaBra</h1>
                <p>Here's your personal feed subscription link. Paste this shit into your podcast app:</p>
                <div class="form-group mb-2">
                    <textarea class="form-control" style="font-size: 10pt; min-height: 64px;" type="text" id="subscriptionLink" readonly :value="subscriptionLink"/>
                </div>
                <div class="form-group mb-2" style="text-align: center;">
                    <button class="btn btn-primary mb-2" @click="copyLink()">Copy Link</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {fbGetAccessLevel, fbSubscribeChannel} from '../assets/apiclient.js'

export default {
    name: 'BroadcastsSubscribe',
    data() {
        return {
            broadcastChannelId: null,
            subscriptionLink: null,
        };
    },
    methods: {
        copyLink() {
            var urlElement = document.getElementById('subscriptionLink');
            urlElement.select();
            urlElement.setSelectionRange(0, 4096);
            navigator.clipboard.writeText(urlElement.value);
            toastr.success("Link copied to clipboard!");
        }
    },
    async created() {
        this.broadcastChannelId = this.$route.params.id;
        console.log("BroadcastChannel", this.broadcastChannelId);
        if (this.broadcastChannelId == null || this.broadcastChannelId == '')
            return;

        if (this.subscriptionLink != null)
            return;
        
        if (fbGetAccessLevel() < 1)
            return;

        const subscribeResult = await fbSubscribeChannel(this.broadcastChannelId);
        if (subscribeResult != null && subscribeResult.rssUrl != '')
            this.subscriptionLink = subscribeResult.rssUrl;
    }
}
</script>