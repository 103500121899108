<template>
    <Navigation :song="song" />
    <AudioPlayer v-if="song.id != null" :contribId="song.id" :contribType="song.type" @song-loading="songLoading" @song-playing="songPlaying" @song-ended="songEnded" @song-paused="songPaused" @song-playtime="songPlaytime"/>
    <section class="ftco-section goto-here pb-0">
        <div class="container"></div>
    </section>

    <router-view @play-song="playSong" :song="song" @enqueue-album="enqueueAlbum"></router-view>
    <SiteFooter></SiteFooter>
</template>

<script>
import Navigation from './components/Navigation'
import AudioPlayer from './components/AudioPlayer'
import SiteFooter from './components/SiteFooter'
import {fbGetSong, fbGetBroadcast, fbGetAlbum} from './assets/apiclient'
import {seek, enqueueSongs, hasSongQueue, nextSongInQueue, seekSongInQueue, getSongQueue} from './assets/audiojs-data';

export default {
    name: 'App',
    data() {
      return {
        song: {
          id: null,
          playTime: 0,
          playState: 'stopped',
          type: '',
          songObject: null,
          songRelated: [],
          relatedAlbums: [],
          songInBroadcast: [],
        }
      }
    },
    methods: {
      enqueueAlbum: async function(albumId) {
        console.log("enqueueAlbum " + albumId);
        let album = await fbGetAlbum(albumId);
        console.log("enqueueAlbum", album);
        let songIds = [];
        album.songs.forEach(item => {
          songIds.push(item.id);
        });
        console.log("enqueueAlbum", songIds);

        enqueueSongs(album.songs);
        if (!hasSongQueue()) {
          let nextSongId = nextSongInQueue();
          this.playSong(nextSongId);
          if (sessionStorage.getItem('fbapp-hint-feat-#36') == null) {
            toastr.info('<p>You have enqueued the whole album in the integrated player, it will automatically play track after track.</p> <p>Feel free to enqueue other albums in the meantime - they will be appended to the playlist.</p> <p>Use the new action icons that were added to the player: <br/><img src="/images/KnobTrackForward.png" height="20"/> skips the current track<br/><img src="/images/playlist1.png" height="20"/> toggles the playlist</p>', "Feature preview", { timeOut: 60000, extendedTimeOut: 120000, preventDuplicates: true });
            await this.wait(10000);
            sessionStorage.setItem('fbapp-hint-feat-#36', 'shown');
          }
        } else {
          toastr.success("The album was appended to the current queue.");
        }
      },
      async seek(seconds) {
        await seek(seconds, this.song.id);
      },
      async seekSongInQueue(index) {
        seekSongInQueue(index, true);
        await this.wait(250);
        let nextSongId = nextSongInQueue();
        this.playSong(nextSongId);
      },
      ensureSongFetched: async function(id) {
        if (this.song.id != null && this.song.songObject != null && this.song.songObject.id == this.id)
          return;

        this.song.songRelated = [];
        this.song.relatedAlbums = [];
        this.song.songInBroadcast = [];

        if (this.song.type != 'broadcast') {
          this.song.songObject = await fbGetSong(id);

          if (this.song.songObject.relatedToSong.length > 0) {
            for (let i = 0; i < this.song.songObject.relatedToSong.length; i++) {
              var item = await fbGetSong(this.song.songObject.relatedToSong[i].id);
              this.song.songRelated[i] = item;
            }
          }

          if (this.song.songObject.inBroadcast.length > 0) {
            for (let i = 0; i < this.song.songObject.inBroadcast.length; i++) {
              var item = await fbGetBroadcast(this.song.songObject.inBroadcast[i].id);
              this.song.songInBroadcast[i] = item;
            }
          }

          if (this.song.songObject.relatedToAlbum.length > 0) {
            for (let i = 0; i < this.song.songObject.relatedToAlbum.length; i++) {
              var item = await fbGetAlbum(this.song.songObject.relatedToAlbum[i].id);
              this.song.relatedAlbums[i] = item;
            }
          }
        } else {
          this.song.songObject = await fbGetBroadcast(id);
        }
      },
      wait: async function(ms) {
        return new Promise(res => setTimeout(res, ms));
      },
      playSong: async function(id, type, offset) {
        let songChanged = false;
        if (this.song.id != id) {
          songChanged = true;
          this.song.id = id;
          this.song.playState = undefined ?? 'stopped';
          this.song.type = type == 'broadcast' ? 'broadcast' : 'song';
          await this.ensureSongFetched(id);
        }
        if (offset == undefined)
          return;
        while (this.song.playState != "playing") {
          console.log("playSong() waiting for playState playing... " + this.song.playState);
          await this.wait(250);
        }
        console.log("playSong() finished waiting", this.song)
        await seek(offset, this.song.id);
      },
      songLoading: async function(id) {
        this.song.id = id;
        this.song.playState = 'loading';
        await this.ensureSongFetched(id);
      },
      songPlaying: async function(id) {
        this.song.id = id;
        this.song.playState = 'playing';
        await this.ensureSongFetched(id);
      },
      songEnded: async function(id) {
        this.song = {
          id: null,
          playTime: 0,
          playState: 'stopped',
          type: '',
          songObject: null,
          songRelated: [],
          relatedAlbums: [],
          songInBroadcast: [],
        };
        if (hasSongQueue) {
          let nextSongId = nextSongInQueue();
          if (nextSongInQueue != null)
            this.playSong(nextSongId);
        }
      },
      songPaused: async function(id) {
        console.log("Song paused", id);
        if (this.song.id != id) {
          console.log("Ignoring song paused event, because it refers to songId " + id + ", but we have songId " + this.song.id);
          return;
        }
        this.song.id = id;
        this.song.playState = 'paused';
        await this.ensureSongFetched(id);
      },
      songPlaytime: function(secs) {
        this.song.playTime = secs;
      }
    },
    components: {
        Navigation,
        AudioPlayer,
        SiteFooter,
    },
    created: function() {
    }
}
</script>

<style>
</style>
