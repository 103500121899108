<template>
<section v-if="videoData !== null" class="hero-wrap clearfix" style="text-align: center; background-size: cover; background-repeat: no-repeat; background-image: url(/images/flabra3.jpg);" data-stellar-background-ratio="0.5">
    <div class="overlay" style="opacity: 0 !important;"></div>
    <video style="width: 65% !important; height: auto !important;" id="fbvideo" autoplay controls playsinline>
        <source v-for="format in videoData" :key="format.id" :src="getPlayUrl(format.href)" :type="getMimeType(format.type)"/>
    </video>
    <h3>{{videoTitle}}</h3>
    {{videoMeta}}
</section>
</template>

<script>
import axios from 'axios'
import {fbGetVideo, fbGetBroadcast, fbLogin, fbGetAccessLevel, fbDirectDownloadEnabled} from '../assets/apiclient'
import {pauseAudioJs} from '../assets/audiojs-data'

export default {
    name: 'VideoPlayer',
    data() {
        return {
            videoData: null,
            videoTitle: "",
            videoMeta: "",
        }
    },
    props: {
        contribId: String,
    },
    methods: {
        getDate(date) {
            var d = new Date(date);
            return (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
        },
        getPlayUrl(value) {
            return value + '/play';
        },
        getMimeType(value) {
            return 'video/' + value;
        },
        async update() {
            var video;
            if (this.contribType != 'broadcast') {
                console.log("VideoPlayer will load this as a video, because contribType is " + this.contribType, this.contribId);
                video = await fbGetVideo(this.contribId);
            }
            // console.log("VideoPlayer got a response", video);
            this.videoTitle = video.name;
            this.videoMeta = video.by.name + " (" + this.getDate(video.releaseInfo.releaseDate) + ")";

            var chosenDefaultIndex = 0;
            var chosenFormats = [];
            if (fbGetAccessLevel() != 0) {
                var bestLevel = 0;
                var index = 0;
                video.formats.forEach(element => {
                    if (element.accessLevel <= fbGetAccessLevel() && element.accessLevel > bestLevel) {
                        bestLevel = element.accessLevel;
                        chosenDefaultIndex = index;
                    }
                    index++;
                });

                video.formats.forEach(element => {
                    if (element.accessLevel == bestLevel)
                        chosenFormats.push(element);
                });
            }

            if (fbGetAccessLevel() != 0 && fbGetAccessLevel() < video.formats[chosenDefaultIndex].accessLevel) {
                toastr.error("Sorry, your current access-level does not satisfy the access-level required by the video's audio data. Unless you login with a real account, I won't be able to play this piece of shit for you.");
                return;
            }

            var dataUrl = video.formats[chosenDefaultIndex].href;
            console.log("DataUrl", dataUrl);
            var response;

            if (fbDirectDownloadEnabled) {
                try {
                    response = await axios({
                        method: 'get',
                        headers: {
                            Range: "bytes=0-3"
                        },
                        url: dataUrl + "/play"
                    });
                } catch (e) {
                    console.log("Error loading contrib", dataUrl);
                    return fbLogin();
                }

                pauseAudioJs();
                this.videoData = chosenFormats;
            } else {
                try {
                    response = await axios({
                        method: 'get',
                        responseType: 'arraybuffer',
                        url: dataUrl + "/play"
                    });
                } catch (e) {
                    console.log("Error loading contrib", dataUrl);
                    return fbLogin();
                }

                var mimeType = response.headers['content-type'].toLowerCase();
                var data = Buffer.from(response.data, 'binary').toString('base64');

                pauseAudioJs();
                this.videoData = "data:" + mimeType + ";base64," + data;
            }
        }
    },
    async updated() {
        // await this.update()
    },
    async created() {
        await this.update()
    }
}
</script>