<template>
    <li class="list-group-item bg-dark" id="song{{song.id}}">
        <button class="song-item" @click="$emit('play-song', song.id)">
            {{song.index}}. {{song.name}}
            <small>({{getSongDuration(song.duration)}})</small>

            <img src="/images/loadspin.gif" height="20" :style="{display: showPlayState(song.id, 'loading') ? 'inline' : 'none'}">
            <a href="#" @click="togglePlayPause(song.id)">
                <img src="/images/white-play-icon-7.jpg" height="20" :style="{display: showPlayState(song.id, 'stopped') ? 'inline' : 'none'}">
                <img src="/images/icons8-audio-64.png" height="20" :style="{display: showPlayState(song.id, 'playing') ? 'inline' : 'none'}">
                <img src="/images/icons8-mute-64.png" height="20" :style="{display: showPlayState(song.id, 'paused') ? 'inline' : 'none'}">
            </a>
        </button>
    </li>
</template>

<script>
import {playPauseAudioJs} from '../assets/audiojs-data'

export default {
    name: 'PlainSong',
    props: {
        song: Object,
        currentSong: Object
    },
    methods: {
        togglePlayPause(songId) {
            if (this.currentSong == undefined || this.currentSong.id != songId) {
                console.log('togglePlayPause ignored', songId, this.currentSong);
                return;
            }
            console.log('togglePlayPause', songId, this.currentSong);
            playPauseAudioJs();
        },
        showPlayState(id, from) {
            if (this.currentSong != null && this.currentSong.id == id)
                console.log("Checking playState for song id " + id + " for display state " + from + " (Current playState: " + this.currentSong.playState + ")");
            var result = 'stopped';
            if (this.currentSong == null)
                return result == from;
            if (this.currentSong.id != id)
                return result == from;
            return this.currentSong.playState == from;
        },
        getSongDuration(secs) {
            var minutes = Math.floor(secs / 60);
            var seconds = secs % 60;
            if (seconds < 10)
                seconds = '0' + seconds;
            return minutes + ':' + seconds;
        }
    }
}
</script>
