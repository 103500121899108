console.log("audiojs-data initializing");
if (currentSong == undefined) {
    var currentSong = null;
    console.log("currentSong initialized");
}
if (isPlaying == undefined) {
    var isPlaying = false;
    console.log("isPlaying initialized");
}
if (songInfo == undefined) {
    var songInfo = null;
    console.log("songInfo initialized");
}
if (playTriggered == undefined) {
    var playTriggered = false;
    console.log("playTriggered initialized");
}
if (playTime == undefined) {
    var playTime = 0;
    console.log("playTime initialized");
}
if (currentSongId == undefined) {
    var currentSongId = null;
    console.log("currentSongId initialized");
}
if (playQueue == undefined) {
    var playQueue = [];
    console.log("playQueue initialized");
}
if (playQueueIndex == undefined) {
    var playQueueIndex = null;
    console.log("playQueueIndex initialized");
}

const ajstimer = ms => new Promise(res => setTimeout(res, ms))

function clearAudioJs() {
    // if (isPlaying) {
    //     console.log("I believe a song is playing, need to stop it first");
    //     currentSong.playPause();
    //     isPlaying = false;
    //     console.log("I believe I have silenced the audio");
    //     // $('[class^="audiojs"]').remove();
    // }
}

function enqueueSongs(songs) {
    if (songs.length == 0)
        return;

    playQueue = playQueue.concat(songs);
    // if (playQueueIndex == null)
    //     playQueueIndex = 0;
}

function hasSongQueue() {
    // console.log("hasSongQueue()", playQueue.length, playQueueIndex);
    return playQueue.length > 0 && playQueueIndex != null;
}

function getSongQueue() {
    return playQueue;
}

function currentSongInQueue() {
    return playQueueIndex;
}

function nextSongInQueue() {
    console.log("nextSongInQueue()");
    if (playQueueIndex == null)
        playQueueIndex = -1;
        
    playQueueIndex++;
    if (playQueueIndex >= playQueue.length) {
        playQueueIndex = null;
        playQueue = [];
        return null;
    }

    return playQueue[playQueueIndex].id;
}

function seekSongInQueue(qindex, seekRightBefore = false) {
    playQueueIndex = qindex;
    if (playQueueIndex > playQueue.length)
        playQueueIndex = playQueue.length - 1;
    if (seekRightBefore)
        playQueueIndex--;
}

async function runAudioJs(song, callbackHandler, playEvent, endedEvent, pausedEvent, timeEvent) {
    if (songInfo != null && song.id == songInfo.id) {
        console.log("Will not start the currently playing song another time");
        return;
    }
    songInfo = song;
    var audios;
    var songInScope = song;
    var songReleaseDate = new Date(songInScope.releaseInfo.releaseDate);
    var songFormatDate = songReleaseDate.getFullYear();

    currentSong = document.getElementById('fbplayer');
    while (currentSong == undefined) {
        console.log("Waiting to attach to fbplayer for song id " + song.id);
        await ajstimer(250);
        currentSong = document.getElementById('fbplayer');
    }

    currentSong.onplay = (event) => {
        toastr.info(songInScope.by.name + "<br/><br/><small>&copy; " + songFormatDate + " FlaBra Music</small>", songInScope.name);
        isPlaying = true;
        currentSongId = songInScope.id;
        playEvent(callbackHandler, songInScope.id);
    }
    currentSong.onended = (event) => {
        console.log('end triggered');
        isPlaying = false;
        currentSong = null;
        currentSongId = null;
        endedEvent(callbackHandler, songInScope.id);
    }
    currentSong.onpause = (event) => {
        console.log('pause triggered');
        isPlaying = false;
        pausedEvent(callbackHandler, songInScope.id);
    }
    currentSong.ontimeupdate = (event) => {
        var theTime = Math.trunc(currentSong.currentTime);
        if (theTime != playTime) {
            playTime = theTime;
            timeEvent(callbackHandler, playTime);
        }
    }
}

function pauseAudioJs() {
    console.log('pauseAudioJs, isPlaying', isPlaying, currentSong);
    if (currentSong == null)
        return;

    if (isPlaying) {
        currentSong.pause();
        isPlaying = false;
    }
}

function playPauseAudioJs() {
    isPlaying = !isPlaying;
    console.log('playPauseAudioJs, isPlaying', isPlaying, currentSong);
    if (currentSong == null)
        return;

    if (isPlaying) {
        currentSong.play();
    } else {
        currentSong.pause();
    }
}

function forward(seconds) {
    if (currentSong == null)
        return;

    currentSong.currentTime += seconds;
}

function rewind(seconds) {
    if (currentSong == null)
        return;

    if (currentSong.currentTime >= seconds)
        currentSong.currentTime -= seconds;
    else
        currentSong.currentTime = 0;
}

async function seek(seconds, songId) {
    console.log("seek(): watching currentSong", songId, currentSong)
    while (currentSong == undefined) {
        console.log("seek(): Waiting to attach to fbplayer for any song");
        await ajstimer(250);
    }

    while (currentSongId != songId) {
        console.log("seek(): Waiting for songId " + songId + " to match currentSongId " + currentSongId)
        await ajstimer(250);
    }

    currentSong.currentTime = seconds;
}

function setPlayTriggered(boolsw) {
    console.log("Set playTriggered", boolsw);
    if (!boolsw) {
        console.log("Delaying set playTriggered to false");
        setTimeout(function() {
            console.log("Delayed execution of set playTriggered to false");
            playTriggered = boolsw;
        }, 500);
    } else {
        playTriggered = boolsw;
    }
}

function getPlayTriggered() {
    console.log("Checking playTriggered", playTriggered);
    return playTriggered;
}

export {runAudioJs, pauseAudioJs, playPauseAudioJs, clearAudioJs, setPlayTriggered, getPlayTriggered, forward, rewind, seek, enqueueSongs, hasSongQueue, getSongQueue, currentSongInQueue, seekSongInQueue, nextSongInQueue}