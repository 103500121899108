<template>
    <VideoPlayer :contribId="this.$route.params.id"></VideoPlayer>
</template>

<script>
import {fbGetVideo} from  '../assets/apiclient'
import VideoPlayer from '../components/VideoPlayer'

export default {
  name: 'Video',
  components: {
      VideoPlayer
  },
  data() {
      return {
        video: null,
      }
  },
  async created() {
      this.video = await fbGetVideo(this.$route.params.id);
  },
}
</script>

<style>
</style>
