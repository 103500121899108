<template>
    <li class="list-group-item bg-dark" id="song{{video.id}}">
        <button class="song-item">
            {{video.name}}
            <small class="text-muted">(ALPHA)</small>
            <router-link :to="{name: 'videos', params: { id: video.id }}">
                <img src="/images/white-play-icon-7.jpg" height="20" :style="inline">
            </router-link>
        </button>
    </li>
</template>

<script>
export default {
    name: 'PlainVideoWithoutContext',
    props: {
        video: Object,
    },
    methods: {
    }
}
</script>
