<template>
    <li v-if="hasSongAndNotIsStopped(song.id)" class="nav-item cta">
        <b>{{ song.songObject.name }}</b>
        <small>({{ getSongPlayTime(song.playTime) }})</small>
        <a href="#" @click="rewind(song.id, 10)">
            <img src="/images/KnobFastRewind.png" height="20" :style="{display: hidePlayState(song.id, 'loading') ? 'inline' : 'none'}">
        </a>
        <img src="/images/loadspin.gif" height="20" :style="{display: showPlayState(song.id, 'loading') ? 'inline' : 'none'}">
        <a href="#" @click="togglePlayPause(song.id)">
            <img src="/images/KnobPlayGreen.png" height="20" :style="{display: showPlayState(song.id, 'playing') ? 'inline' : 'none'}">
            <img src="/images/KnobPlay.png" height="20" :style="{display: showPlayState(song.id, 'paused') ? 'inline' : 'none'}">
        </a>
        <a href="#" @click="forward(song.id, 30)">
            <img src="/images/KnobFastForward.png" height="20" :style="{display: hidePlayState(song.id, 'loading') ? 'inline' : 'none'}">
        </a>
        <a href="#" @click="forward(song.id, 86400)">
            <img src="/images/KnobTrackForward.png" height="20" :style="{display: hasQueue() ? 'inline' : 'none'}">
        </a>
        <a href="#" @click="bcinfo()">
            <img src="/images/KnobInfo.png" height="20" :style="{display: isBroadcast(song.id) ? 'inline' : 'none'}">
        </a>
        <a href="#" @click="playlist()">
            <img src="/images/playlist1.png" height="18" :style="{display: hasQueue() ? 'inline' : 'none'}">
        </a>
        <br/>
        <small><router-link :to="{name: 'author', params: { id: song.songObject.by.id }}">{{ song.songObject.by.name }}</router-link></small>
    </li>
</template>

<script>
import {playPauseAudioJs, forward, rewind, seek, hasSongQueue, getSongQueue, currentSongInQueue} from '../assets/audiojs-data'

export default {
    name: 'EmbeddedAudioPlayer',
    props: {
        song: Object,
    },
    data() {
        return {
            sortedContribs: [],
        }
    },
    methods: {
        togglePlayPause(songId) {
            console.log('togglePlayPause', songId);
            playPauseAudioJs();
        },
        forward(songId, seconds) {
            console.log('forward', seconds, songId);
            forward(seconds);
        },
        rewind(songId, seconds) {
            console.log('rewind', seconds, songId);
            rewind(seconds);
        },
        seek(seconds) {
            console.log('seek', seconds);
            seek(seconds, this.song.songObject.id);
        },
        isThisPlaying(contribElement) {
            if (this.song.playTime < contribElement.offset)
                return false;
            if (this.song.playTime > contribElement.offset + contribElement.duration)
                return false;
            return true;
        },
        bcinfo() {
            var items = [];
            items = items.concat(this.song.songObject.contribs);
            items = items.concat(this.song.songObject.songs);
            items.sort((a, b) => {
                var x = a.offset - b.offset;
                if (x == 0)
                    return 0;
                if (x > 0)
                    return 1;
                return -1;
            });
            
            var res = '<br/><small>';
            items.forEach(contrib => {
                var item = '<a href="#" onclick="window.app.seek(' + contrib.offset + ', \'' + this.song.songObject.id + '\')">(' + this.getSongPlayTime(contrib.offset) + ') ' + contrib.name;
                if (this.isThisPlaying(contrib))
                    item = '<b>' + item + '</b>';
                res += item + '</a><br/>';
            });
            res += '</small>';

            if (this.song.songObject.description != null)
                res += '<br/><small>' + this.song.songObject.description + '</small>';

            toastr.info(res, this.song.songObject.name + '<br/><small>' + this.song.songObject.by.name + ' (' + this.getDate(this.song.songObject.releaseInfo.releaseDate) + ')</small>', { timeOut: 60000, extendedTimeOut: 120000, preventDuplicates: true });
        },
        playlist() {
            var items = getSongQueue();
            var current = items[currentSongInQueue()];
            var count = 0;

            var res = '<br/><small><ol>';
            items.forEach(song => {
                var item = '<li><a href="#" onclick="window.app.seekSongInQueue(' + count + ')">' + song.name;
                if (song.id == current.id)
                    item = '<b>' + item + '</b>';
                item = item + ' (' + this.getSongPlayTime(song.duration) + ')';
                res += item + '</a></li>';
                count++;
            });
            res += '</ol></small>';

            if (this.song.songObject.description != null)
                res += '<br/><small>' + this.song.songObject.description + '</small>';

            toastr.info(res, 'Playlist', { timeOut: 60000, extendedTimeOut: 120000, preventDuplicates: true });
        },
        hasSongAndNotIsStopped(id) {
            if (this.song == null)
                return false;
            if (this.song.id == null)
                return false;
            if (this.song.playState == null)
                return false;
            if (this.song.songObject == null)
                return false;
            if (this.song.songObject.name == null)
                return false;
            if (this.song.playState == 'stopped')
                return false;

            return true;
        },
        isBroadcast(id) {
            if (this.song == null)
                return false;
            if (this.song.type == 'broadcast')
                return true;
            return false;
        },
        hasQueue() {
            return hasSongQueue();
        },
        showPlayState(id, from) {
            var result = 'stopped';
            if (this.song == null)
                return result == from;
            if (this.song.id != id)
                return result == from;
            return this.song.playState == from;
        },
        hidePlayState(id, from) {
            var result = 'stopped';
            if (this.song == null)
                return result != from;
            if (this.song.id != id)
                return result != from;
            return this.song.playState != from;
        },
        getDate(date) {
            var d = new Date(date);
            return (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
        },
        getSongPlayTime(secs) {
            var minutes = Math.floor(secs / 60);
            var seconds = secs % 60;
            if (seconds < 10)
                seconds = '0' + seconds;
            return minutes + ':' + seconds;
        },
    },
}
</script>
