<template>
    <li class="list-group-item bg-dark" id="song{{song.id}}">
        <button class="song-item" @click="$emit('play-song', song.id)">
            {{song.name}}
            <small>({{getSongDuration(song.duration)}})</small>

            <img src="/images/loadspin.gif" height="20" :style="{display: showPlayState(song.id, 'loading') ? 'inline' : 'none'}">
            <a href="#" @click="togglePlayPause(song.id)">
                <img src="/images/white-play-icon-7.jpg" height="20" :style="{display: showPlayState(song.id, 'stopped') ? 'inline' : 'none'}">
                <img src="/images/icons8-audio-64.png" height="20" :style="{display: showPlayState(song.id, 'playing') ? 'inline' : 'none'}">
                <img src="/images/icons8-mute-64.png" height="20" :style="{display: showPlayState(song.id, 'paused') ? 'inline' : 'none'}">
            </a>
        </button>
        {{relation.linkType}}
        {{describeSongRelation(relation)}}
    </li>
</template>

<script>
import {playPauseAudioJs} from '../assets/audiojs-data'

export default {
    name: 'PlainSongRelated',
    props: {
        song: Object,
        relation: Object,
        currentSong: Object
    },
    methods: {
        togglePlayPause(songId) {
            console.log('togglePlayPause', songId);
            playPauseAudioJs();
        },
        showPlayState(id, from) {
            // if (this.currentSong != null && this.currentSong.id == id)
            //     console.log("PlainSongRelated: Checking playState for song id " + id + " for display state " + from);
            // console.log("PlainSongRelated: currentSong", this.currentSong);

            var result = 'stopped';
            if (this.currentSong == null)
                return result == from;
            if (this.currentSong.id != id)
                return result == from;
            return this.currentSong.playState == from;
        },
        getSongDuration(secs) {
            var minutes = Math.floor(secs / 60);
            var seconds = secs % 60;
            if (seconds < 10)
                seconds = '0' + seconds;
            return minutes + ':' + seconds;
        },
        describeSongRelation(relation) {
            var res = 'Failed to obtain relation data';
            switch (relation.linkType) {
                case 'RerecordedFrom':
                    res = 'This song has been re-recorded from the original source song "' + relation.name + '"';
                    break;

                case 'RerecordedAs':
                    res = 'This song was re-recorded as song "' + relation.name + '"';
                    break;

                case 'RemixedFrom':
                    res = 'This song is a remix of the original source "' + relation.name + '"';
                    break;
                
                case 'RemixedAs':
                    res = 'This song was remixed as "' + relation.name + '"';
                    break;

                case 'SampledFrom':
                    res = 'This song contains samples from the original source "' + relation.name + '"';
                    break;

                case 'SampledAs':
                    res = 'Parts of this song have been sampled in the song "' + relation.name + '"';
                    break;

                case 'PredecessorOf':
                    res = 'Another version of this song was recorded later under the name "' + relation.name + '"';
                    break;

                case 'SuccessorOf':
                    res = 'This song is a later version of the original source "' + relation.name + '"';
                    break;

                case 'AlteredFrom':
                    res = 'This song is an alternative take or mix of "' + relation.name + '"';
                    break;

                case 'AlteredAs':
                    res = 'This song is an alternative take or mix of "' + relation.name + '"';
                    break;

                case 'CoveredFrom':
                    res = 'This song is a cover song based on the original source "' + relation.name + '"';
                    break;

                case 'CoveredAs':
                    res = 'This song is the original source of the covered version named "' + relation.name + '"';
                    break;

                case 'PerformedFrom':
                    res = 'This song is a live performance of the the original source "' + relation.name + '"';
                    break;

                case 'PerformedAs':
                    res = 'This song has been recorded live as "' + relation.name + '"';
                    break;
                    
                case 'Inspired':
                    res = 'This song inspired the creation of "' + relation.name + '"';
                    break;

                case 'InspiredBy':
                    res = 'This song was inspired by the source "' + relation.name + '"';
                    break;
                    
                default:
                    break;
            }

            return res;
        }
    }
}
</script>
