<template>
    <li class="list-group-item bg-dark">
        <router-link :to="{name: 'release', params: { id: album.id }}" class="card-title" style="color: white;">
            <AuthImage :src="[album.images.filter((item) => item.purpose === 'thumbnail')[0].href]" class="card-img" style="max-width: 64px; border-radius: 4px;" alt="..." />
            {{album.by.name}} - {{album.name}} ({{getDate(album.releaseInfo.releaseDate)}})
        </router-link>
    </li>
</template>

<script>
import AuthImage from './AuthImage.vue';

export default {
    name: 'AlbumReference',
    components: {
        AuthImage,
    },
    props: {
        album: Object,
    },
    methods: {
        getDate(date) {
            var d = new Date(date);
            return (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
        },        
    }
}
</script>
