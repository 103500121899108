<template>
    <div class="form-group row">
        <label data-toggle="tooltip" data-placement="right" data-html="true" v-bind:title="property.description" v-bind:for="property.id" class="col-sm-4 col-form-label" style="color: #606060;">Device Identifier</label>
        <div class="col-sm-8">
            <div class="input-group mb-2">        
                <input v-if="isEditing(property.id)" type="text" class="form-control" v-bind:id="property.id" v-bind:value="property.value">
                <input v-if="!isEditing(property.id)" @click="edit(property.id)" type="text" class="form-control-plaintext" v-bind:id="property.id" v-bind:value="property.value" required>
                <div class="input-group-prepend">
                    <a data-toggle="modal" v-bind:data-target="'#modalDelete' + property.id" href="#" class="input-group-text"><img src="/images/trashcan.png" height="24" /></a>
                    <a href="#" @click="edit(property.id)" class="input-group-text"><img v-bind:src="editOptionsImageUrl(property.id)" height="24" /></a>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" v-bind:id="'modalDelete' + property.id" tabindex="-1" role="dialog" aria-labelledby="modalDisableLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm your choice</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-bind:id="'modalDelete' + property.id + '-modal-body'" class="modal-body">
                    <strong>Do you really want to delete this 2FA device?</strong><br/><br/>
                    <p>
                        <center><strong style="color: #b0b0b0;">{{property.value}}</strong></center>
                    </p>
                    This operation cannot be undone. If this is your only device left, deleting it will completely disable 2FA for your account.
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal" @click="deleteDevice(property.id)">Proceed</button>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>

export default {
  components: {},
    name: 'ProfileDeviceProperty',
    props: {
        property: Object,
    },
    data() {
        return {
            currentEditingId: null,
        }
    },
    methods: {
        isEditing(id) {
            return (id == this.currentEditingId)
        },
        edit(id) {
            if (!this.isEditing(id)) {
                this.currentEditingId = id;
                return;
            }

            // commit...
            this.$emit('profile-property-device-update', this.property.id, document.getElementById(this.property.id).value);
            this.currentEditingId = null;
        },
        deleteDevice(id) {
            this.$emit('profile-propery-device-delete', this.property.id);
        },
        editOptionsImageUrl(id) {
            return '/images/' + (this.isEditing(id) ? 'editok.png' : 'edit.png');
        }
    },
}
</script>