<template>
<section class="hero-wrap clearfix" style="background-size: cover; background-repeat: no-repeat; background-blend-mode: multiply; background-color: #505050; background-image: url(images/flabra2.jpg);" data-stellar-background-ratio="0.1">
    <div class="overlay"></div>

    <div v-if="getAccessLevel() > 0">
        <section class="newsletter-testimonials-area" style="position: absolute; right: 0px; max-width: 540px;">
            <div class="testimonials-area mb-100 bg-img bg-overlay" style="background-repeat: no-repeat; background-size: cover; background-image: url(images/fancydevices.jpg);">
                <small style="color: #d0d0d0;">Don't miss our show updates!</small>
                <div class="testimonials-slide">
                    <div class="single-slide">
                        <router-link :to="{name: 'podcast-subscribe', params: { id: broadcastChannelId }}">
                            <p><img src="/images/rss.png" height="32"/> Subscribe to Radio FlaBra's <strong>RSS.XML</strong> feed</p>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="container">
        <h1>PODCASTS</h1>
    </div>

    <section class="section-padding-100-0">
        <div class="container">
            <div class="row oneMusic-albums">
                <div :key="broadcast.id" v-for="broadcast in broadcasts">
                    <BroadcastsItem :broadcast="broadcast" />
                </div>
            </div>
        </div>
    </section>
</section>

</template>

<script>
import BroadcastsItem from '../components/BroadcastsItem'
import {fbLogin, fbGetAccessLevel, fbGetBroadcasts} from '../assets/apiclient.js'

export default {
  name: 'Broadcasts',
  components: {
    BroadcastsItem,
  },
  data() {
      return {
        broadcasts: [],
        broadcastChannelId: null,
      }
  },
  methods: {
    updateChannelId() {
      this.broadcastChannelId = this.broadcasts[0].channel;
    },
    getAccessLevel() {
      return fbGetAccessLevel();
    }
  },
  async created() {
      this.broadcasts = JSON.parse(sessionStorage.getItem('fb-broadcasts'));
      if (this.broadcasts != null) {
        this.updateChannelId();
        return;
      }

      this.broadcasts = await fbGetBroadcasts();
      sessionStorage.setItem('fb-broadcasts', JSON.stringify(this.broadcasts));
      this.updateChannelId();
  }
}
</script>

<style>
</style>
