<template>
<nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
    <div class="container">
        <router-link class="navbar-brand" to="/"><img src="/images/flabra-logo-small.png" height="32"></router-link>
        <ul class="navbar-nav ml-auto" style="padding-right: 0; margin-right: 0;">
            <EmbeddedAudioPlayer :song="song"/>
        </ul>
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
            Menu
        </button>
        <div class="collapse navbar-collapse" id="ftco-nav"> -->
            <ul class="navbar-nav ml-auto" style="display: inline-block;">
                <li class="nav-item " style="display: inline-block;">
                    <router-link to="/releases" class="nav-link" style="display: inline-block;">Releases</router-link>
                </li>
                <li class="nav-item " style="display: inline-block;">
                    <router-link to="/podcasts" class="nav-link" style="display: inline-block;">Podcasts</router-link>
                </li>
                <!-- <li v-if="!isLoggedIn" class="nav-item cta">
                    <a href="#" v-on:click="apiLogin()" class="nav-link">Login</a>
                </li> -->
                <li v-if="isLoggedIn" class="nav-item dropdown" style="display: inline-block;">
                    <a class="nav-link dropdown-toggle" href="#" id="account" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  style="display: inline-block;">
                        <img src="/images/account.png" height="28">
                    </a>
                    <div class="dropdown-menu" aria-labelledby="account">
                        <!-- <a v-if="accessLevel > 1" href="#" v-on:click="apiLogout()" class="dropdown-item">Profile</a> -->
                        <router-link to="/profile" v-if="accessLevel > 1" class="dropdown-item">Profile</router-link>
                        <a class="dropdown-item" href="#" v-on:click="apiLogout()">Logout</a>
                    </div>
                </li>
            </ul>
        <!-- </div> -->
    </div>
</nav>
</template>

<script>
import {fbLogin, fbLogout, fbIsLoggedIn, fbGetAccessLevel} from '../assets/apiclient'
import EmbeddedAudioPlayer from './EmbeddedAudioPlayer'

export default {
    name: 'Navigation',
    components: {
        EmbeddedAudioPlayer,
    },
    data() {
        return {
            isLoggedIn: false,
            accessLevel: 0,
        }
    },
    props: {
        song: Object,
    },
    methods: {
        apiLogin() {
            return fbLogin();
        },
        apiLogout() {
            return fbLogout();
        }
    },
    async created() {
        this.isLoggedIn = await fbIsLoggedIn();
        this.accessLevel = fbGetAccessLevel();
    }
}
</script>