<template>
    <footer class="ftco-section ftco-section-2">
        <div class="col-md-12 text-center">
            <p class="mb-0">
                Flabra Planet 2 &copy; 2023 Flabra Music<br/>
                <router-link to="/changelog">v{{ appInfo.version }} - {{ appInfo.buildDate }}</router-link>
            </p>
        </div>
    </footer>
</template>

<script>
import {getAppInfo} from '../assets/appinfo'

export default {
    name: 'SiteFooter',
    data() {
        return {
            appInfo: {},
        }
    },
    created() {
        this.appInfo = getAppInfo();
        console.log("AppInfo", this.appInfo);
    }
}
</script>
