<template>
    <!-- <AudioPlayer v-if="songId != null" :songId="songId" @song-loading="songLoading"/> -->

    <AlbumReleasesDetail :album="album" :currentSong="song" @play-song="playSong" :key="componentKey"/>
</template>

<script>
import AudioPlayer from '../components/AudioPlayer'
import AlbumReleasesDetail from '../components/AlbumReleasesDetail'
import {fbGetAlbum} from '../assets/apiclient.js'

export default {
  name: 'App',
  components: {
    AlbumReleasesDetail,
    AudioPlayer
  },
  data() {
      return {
        album: null,
        componentKey: 0
      }
  },
  props: {
      song: Object,
  },
  methods: {
      playSong: function(id, type) {
          console.log("PublistDetail:playSong", id, type);
          this.$emit('play-song', id, type);
      },
      songLoading: function(id) {
          // console.log("Noting event: song-loading", id);
      },
  },
  async created() {
      this.album = await fbGetAlbum(this.$route.params.id);
  },
  async updated() {
      if (this.album != null && this.album.id != this.$route.params.id) {
        this.album = await fbGetAlbum(this.$route.params.id);
        this.componentKey += 1;
      }
  },
}
</script>

<style>
</style>
