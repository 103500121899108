<template>
    <img :src="imageData" v-bind="$attrs"/>
</template>

<script>
import axios from 'axios'
import {fbDirectDownloadEnabled} from '../assets/apiclient';

export default {
    name: 'AuthImage',
    data() {
        return {
            imageData: ""
        }
    },
    props: {
        src: String,
    },
    async created() {
        if (fbDirectDownloadEnabled) {
            this.imageData = this.src;
            return;
        }

        var response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: this.src
        });
        var mimeType = response.headers['content-type'].toLowerCase();
        var data = Buffer.from(response.data, 'binary').toString('base64');
        this.imageData = "data:" + mimeType + ";base64," + data;
    }
}
</script>