<template>
<section class="hero-wrap clearfix" style="background-size: cover; background-repeat: no-repeat; background-image: url(/images/flabra2.jpg);" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container container-top">
        <div v-if="broadcast !== null" class="row">
            <div class="col-sm">
                <!-- <h1>RELEASE</h1> -->

                <div class="card mb-3 bg-dark" style="max-width: 600px; border-radius: 12px;">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                            <img src="/images/podcast-icon.svg" class="card-img" style="max-width: 190px;" alt="..." />
                            <p class="card-text"><small class="text-muted">&copy; {{getDate(broadcast.releaseInfo.releaseDate)}} Flabra Music (~{{getDuration(broadcast.duration)}} mins)</small></p>
                            <li class="list-group-item bg-dark" id="broadcast{{broadcast.id}}">
                                <button class="song-item" @click="$emit('play-song', broadcast.id, 'broadcast')">
                                    <img src="/images/loadspin.gif" height="48" :style="{display: showPlayState(broadcast.id, 'loading') ? 'inline' : 'none'}">
                                    <a href="#" @click="togglePlayPause(broadcast.id)">
                                        <img src="/images/white-play-icon-7.jpg" height="48" :style="{display: showPlayState(broadcast.id, 'stopped') ? 'inline' : 'none'}">
                                        <img src="/images/icons8-audio-64.png" height="48" :style="{display: showPlayState(broadcast.id, 'playing') ? 'inline' : 'none'}">
                                        <img src="/images/icons8-mute-64.png" height="48" :style="{display: showPlayState(broadcast.id, 'paused') ? 'inline' : 'none'}">
                                    </a>
                                </button> Play
                                <p class="card-text" v-if="getSuitableFormat() != null">
                                    <a href="#" @click="useSuitableDownloadUri()">
                                        <img src="/images/ark2.png" height="48" /> Download MP3 <small class="text-muted">({{renderHumanSize(getSuitableFormat().size)}})</small>
                                    </a>
                                </p>
                            </li>
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title"><div class="card-title" style="color: white;">
                                    {{broadcast.name}}
                                </div></h5>
                                <h6 class="card-title"><div class="card-title" style="color: white;">
                                    <router-link :to="{name: 'author', params: { id: broadcast.by.id }}">{{broadcast.by.name}}</router-link>
                                </div></h6>

                                <p v-if="isCensoredBroadcast()" class="card-text" style="background-color: #402020; color: #ffffc0;"><small text="text-muted">
                                    <p><img src="/images/redflag.png" height="12"/>&nbsp;<strong>Censorship information</strong><br/><br/>{{getCensorshipDescription()}}</p>
                                </small></p>

                                <p class="card-text">
                                    <div :key="par" v-for="par in getDescriptionParagraphs()">
                                        <p><small text="text-muted">{{par}}</small></p>
                                    </div>
                                </p>

                                <h7 class="card-title" style="color: white;">Highlights</h7>

                                <p class="card-text">
                                    <ul class="list-group">
                                        <div :key="item.id" v-for="item in getContents()">
                                            <li class="bg-dark"><small class="text-muted">
                                                <a style="color: #aeaeae;" href="#" @click="playOffset(item.offset)">#{{getSongPlayTime(item.offset)}}</a> {{item.by.name}} - {{item.name}}
                                                <small class="text-muted">
                                                    ({{getSongPlayTime(item.duration)}}) <!-- great, we care about cue time here -->
                                                </small>
                                            </small></li>
                                        </div>
                                    </ul>
                                </p>

                                <p class="card-text" v-if="(broadcast.relatedVideos.length > 0)">
                                    <div class="card-title" style="color: white;">
                                        Videos related to this broadcast
                                    </div>
                                    <ul class="list-group">
                                        <div :key="video.id" v-for="video in broadcast.relatedVideos">
                                            <PlainVideoWithoutContext :video="video"></PlainVideoWithoutContext>
                                        </div>
                                    </ul>
                                </p>

                                <!-- <p class="card-text">
                                    <ul class="list-group">
                                        <div :key="song.id" v-for="song in broadcast.songs">
                                            <PlainSong :song="song" :currentSong="currentSong" @play-song="$emit('play-song', song.id)"></PlainSong>
                                        </div>
                                    </ul>
                                </p> -->
                                <!-- <p class="card-text"><small class="text-muted">{$broadcast->releaseTag} &copy; {$broadcast->releaseDate->format('m/Y')} Flabra Music</small></p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios'
import PlainBroadcast from './PlainBroadcast'
import PlainVideoWithoutContext from './PlainVideoWithoutContext'
import {playPauseAudioJs} from '../assets/audiojs-data'
import {fbGetAccessLevel, fbLogin} from '../assets/apiclient'

export default {
    name: 'BroadcastsDetail',
    components: {
        PlainBroadcast,
        PlainVideoWithoutContext,
    },
    props: {
        broadcast: Object,
        currentSong: Object,
    },
    methods: {
        togglePlayPause(songId) {
            if (this.currentSong == undefined || this.currentSong.id != songId) {
                console.log('togglePlayPause ignored', songId, this.currentSong);
                return;
            }
            playPauseAudioJs();
        },
        showPlayState(id, from) {
            var result = 'stopped';
            if (this.currentSong == null)
                return result == from;
            if (this.currentSong.id != id)
                return result == from;
            return this.currentSong.playState == from;
        },
        getDate(date) {
            var d = new Date(date);
            return (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
        },
        getDuration(secs) {
            return Math.ceil(secs / 60);
        },
        getContents() {
            var items = [];
            items = items.concat(this.broadcast.contribs);
            items = items.concat(this.broadcast.songs);
            items.sort((a, b) => {
                var x = a.offset - b.offset;
                if (x == 0)
                    return 0;
                if (x > 0)
                    return 1;
                return -1;
            });
            let count = 1;
            items.forEach(element => {
                element.index = count;
                count++;
            });

            return items;
        },
        playOffset(offset) {
            this.$emit('play-song', this.broadcast.id, 'broadcast', offset);
        },
        getDescriptionParagraphs() {
            return this.broadcast.description.split("\n\n");
        },
        getBroadcastAccessLevels() {
            var result = [];

            this.broadcast.formats.forEach(element => {
                if (!result.includes(element.accessLevel))
                    result.push(element.accessLevel);
            });

            return result.sort();
        },
        getHighestBroadcastAccessLevel() {
            const levels = this.getBroadcastAccessLevels();
            var high = 0;
            levels.forEach(element => {
                if (element > high)
                    high = element;
            });

            return high;
        },
        isCensoredBroadcast() {
            return this.getHighestBroadcastAccessLevel() > 1;
        },
        getCensorshipDescription() {
            const levels = this.getBroadcastAccessLevels();
            if (levels.length == 0)
                return null;
            
            if (!this.isCensoredBroadcast())
                return null;

            var result = "Contains passages that might be inadequate for some listeners. ";
            
            const accessLevel = fbGetAccessLevel();
            if (accessLevel == 0)
                return result;
            
            var version = "";
            if (this.getHighestBroadcastAccessLevel() > accessLevel)
                version = "a censored";
            else
                version = "the fully uncensored";

            return result += "Based on your current AccessLevel, you will be listening to " + version + " version of this broadcast.";
        },
        getSuitableFormat() {
            if (this.broadcast.formats.length == 0)
                return null;

            if (fbGetAccessLevel() == 0)
                return this.broadcast.formats[0]; // choose "any", because we'll be redirected to IdP anyway

            var chosenAudioIndex = 0;
            var bestLevel = 0;
            var index = 0;
            this.broadcast.formats.forEach(element => {
                // console.log("Looking at audio format", element);
                // console.log("AccessLevel(audio format/mine): " + element.accessLevel + "/" + fbGetAccessLevel());
                // console.log("Test: element.accessLevel <= fbGetAccessLevel", element.accessLevel <= fbGetAccessLevel());
                // console.log("Test: element.accessLevel > bestLevel", element.accessLevel > bestLevel);
                if (element.accessLevel <= fbGetAccessLevel() && element.accessLevel > bestLevel) {
                    bestLevel = element.accessLevel;
                    chosenAudioIndex = index;
                    // console.log("Choosing index " + chosenAudioIndex + ", new bestLevel is " + bestLevel);
                }
                index++;
                // console.log("Index is at " + index);
            });
            
            return this.broadcast.formats[chosenAudioIndex];
        },
        async useSuitableDownloadUri() {
            var targetUrl = this.getSuitableFormat().href + '/download';

            try {
                var response = await axios({
                    method: 'get',
                    headers: {
                        Range: "bytes=0-3"
                    },
                    url: targetUrl
                });

                document.location.href = targetUrl;
            } catch (ex) {
                console.log("Error downloading binary " + targetUrl);
                return fbLogin();
            }
        },
        getSongPlayTime(secs) {
            var minutes = Math.floor(secs / 60);
            var seconds = secs % 60;
            if (seconds < 10)
                seconds = '0' + seconds;
            return minutes + ':' + seconds;
        },
        renderHumanSize(bytes) {
            if (bytes < 1024)
                return bytes + ' bytes';
            if (bytes < 1048576)
                return Math.ceil(bytes / 1024) + 'K';
            if (bytes < 1073741824)
                return Math.ceil(bytes / 1048576) + 'M';
            return Math.ceil(bytes / 1099511627776) + 'G';
        }
    }
}
</script>