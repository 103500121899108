<template>
    <div class="form-group row">
        <label data-toggle="tooltip" data-placement="right" data-html="true" v-bind:title="property.description" v-bind:for="property.id" class="col-sm-4 col-form-label" style="color: #606060;">{{property.name}}</label>
        <div class="col-sm-8">
            <input v-if="!property.editable" type="text" readonly class="form-control-plaintext" id="{{property.id}}" v-bind:value="property.value">
            <div v-if="property.editable" class="input-group mb-2">        
                <input v-if="isEditing(property.id)" type="text" class="form-control" v-bind:id="property.id" v-bind:value="property.value">
                <input v-if="!isEditing(property.id)" @click="edit(property.id)" type="text" class="form-control-plaintext" v-bind:id="property.id" v-bind:value="property.value">
                <div class="input-group-prepend">
                    <a href="#" @click="edit(property.id)" class="input-group-text"><img v-bind:src="editOptionsImageUrl(property.id)" height="24" /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  components: {},
    name: 'ProfileProperty',
    props: {
        property: Object,
    },
    data() {
        return {
            currentEditingId: null,
        }
    },
    methods: {
        isEditing(id) {
            return (id == this.currentEditingId)
        },
        edit(id) {
            if (!this.isEditing(id)) {
                this.currentEditingId = id;
                return;
            }

            // commit...
            this.$emit('profile-property-update', this.property.id, document.getElementById(this.property.id).value);
            this.currentEditingId = null;
        },
        editOptionsImageUrl(id) {
            return '/images/' + (this.isEditing(id) ? 'editok.png' : 'edit.png');
        }
    },
}
</script>