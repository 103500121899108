<template>
    <!-- <AudioPlayer v-if="songId != null" :songId="songId" @song-loading="songLoading"/> -->

    <BroadcastsDetail :broadcast="broadcast" :currentSong="song" @play-song="playSong" />
</template>

<script>
import AudioPlayer from '../components/AudioPlayer'
import BroadcastsDetail from '../components/BroadcastsDetail'
import {fbGetBroadcast} from '../assets/apiclient.js'

export default {
  name: 'Broadcast',
  components: {
    BroadcastsDetail,
    AudioPlayer
  },
  data() {
      return {
        broadcast: null,
      }
  },
  props: {
      song: Object,
  },
  methods: {
      playSong: function(id, type) {
        //   console.log("BroadcastsDetail:playSong", id, type);
          this.$emit('play-song', id, type);
      },
      songLoading: function(id) {
        //   console.log("Noting event: song-loading", id);
      },
  },
  async created() {
      this.broadcast = await fbGetBroadcast(this.$route.params.id);
  },
}
</script>

<style>
</style>
